import React, { useEffect } from 'react';
import Container from '../components/layout/default/container';
import SEO from '../components/layout/seo';

const SuccessPage = () => {

  useEffect(() => {
    window.location.replace(`https://centers.funbowlingleagues.com`);
  }, []);


  return (
    <Container>
      <SEO title="Redirecting..." />      
    </Container>
  );
}

export default SuccessPage
